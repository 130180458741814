import axios from "axios";
import configApi from "../../configApi";
import {
    refreshToken
} from "../api/index"
import {
    Dialog
} from 'vant';

let baseURL = configApi.apiHost;
// let baseURL = '';
const service = axios.create({
    baseURL,
    timeout: 5000, // request timeout
});
// 发起请求之前的拦截器
service.interceptors.request.use(
    (config) => {
        // APPID
        config.headers.APPID = configApi.appid;
        config['content-type'] = 'application/json'
        if (config.token == false) {
            return config
        }
        // 如果有token 就携带tokon
        const token = window.localStorage.getItem("accessToken");
        config.headers.Authorization = 'Bearer ' + token || '';

        return config;
    },
    (error) => Promise.reject(error)
);
// 响应拦截器
service.interceptors.response.use(
    (response) => {
        const res = response.data;
        if (response.status !== 200) {
            return Promise.reject(new Error(res.message || "Error"));
        } else {
            if (res.code == 0) {
                // 登录接口不需要弹窗
                if (response.config.url === 'client/login/wechat') {
                    return res;
                }
                // refreshToken(window.localStorage.getItem("refreshToken"))
                Dialog.alert({
                    title: '登录',
                    message: '您还未登录,请先登录后参与',
                }).then(() => {
                    let opt = {
                        url: window.location.href,
                        scope: 'snsapi_userinfo',
                        proxyUrl: 'https://mall.wengwenggu.com/wxoauth.html', //TODO 测试用登陆代理
                    }
                    post('client/login/wechatoauth', opt).then(res => {
                        if (res.code == 1) {
                            window.location.href = res.data.url
                        }
                        // console.log(res.data.url)
                    })
                });
            }
            if (res.code == -900) {
                Dialog.alert({
                    title: '登录',
                    message: '您还未登录,请先登录后参与',
                }).then(() => {
                    let opt = {
                        url: window.location.href,
                        scope: 'snsapi_base',
                        proxyUrl: 'https://mall.wengwenggu.com/wxoauth.html', //TODO 测试用登陆代理
                    }
                    post('client/login/wechatoauth', opt).then(res => {
                        if (res.code == 1) {
                            // window.location.href = res.data.url
                        }
                        console.log(res.data.url)
                    })
                });
            }
            // 刷新token
            if (res.code == -996) {
                refreshToken(window.localStorage.getItem("refreshToken"))
            }
            return res;
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default service;

/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function get(url, params = {}, autoRoter = true) {
    return service({
        url,
        params,
        method: 'get'
    }, autoRoter)
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}, autoRoter = true) {
    return service({
        url,
        data,
        method: 'post'
    }, autoRoter)
}