<template>
    <div id="app"><router-view /></div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRouter, onBeforeRouteUpdate } from 'vue-router'
import { GetQueryString } from './utils/tools'
import { loginWechat, flush_time } from './api/index'

// console.log(window.location.href);
let url = window.location.href
window.localStorage.setItem('backUrl', url)

let code = GetQueryString('code')
// console.log(code)

if (code) {
    loginWechat(code).then((res: any) => {
        if (res.code == 1) {
            window.localStorage.setItem('accessToken', res.data.token.token)
            window.localStorage.setItem('refreshToken', res.data.token.refresh_token)
        }
    })
}

// imgInfo 页面单独设置一个全屏背景颜色
// 因页面有两个 swiper 组件 不能放在一个 div 中（无法滑动）
let router = useRouter()
const appBackground = ref('#fff')
watch(
    () => router.currentRoute.value.path,
    newValue => {
        window.scrollTo(0, 0)
        if (newValue === '/golf/imgInfo') {
            appBackground.value = '#121314'
        } else {
            appBackground.value = '#fff'
        }

        flush_time(router.currentRoute.value)
    },
    { immediate: true }
)
</script>

<style>
#app {
    font-size: 16px;
    min-height: 100vh;
    background-color: v-bind(appBackground);
}
:root {
    --van-popup-round-border-radius: 12px;
}
</style>
