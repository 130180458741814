import request from "../utils/request";
import {
    post
} from "../utils/request";
import store from "../store";
import jsonp from "jsonp";
import wx from 'weixin-js-sdk'

//微信登录
export function loginWechat(parm) {
    const data = {
        code: parm,
    };
    return request({
        url: "client/login/wechat",
        data,
        token: false,
        method: "POST",
    }).then((res) => {
        return res
        // return store.dispatch("onLogin", res);
    });
}

// 刷新token
export const refreshToken = (token) => {
    return request({
        url: "client/login/refreshToken",
        data: {
            token,
        },
        method: "post",
    }).then((res) => {
        window.localStorage.setItem("accessToken", res.data.token.token);
        window.localStorage.setItem("refreshToken", res.data.token.refresh_token);
        return store.dispatch("onLogin", res);
    });
};

// 获取参数二维码
export const qrcode = (origin) => {
    return request({
        url: "service/tools/qrcode",
        data: {
            token,
        },
        method: "post",
    });
};

// 腾讯地图获取定位
export const getLocationDistance = (destination) => {
    let mapKey = 'FNHBZ-JGJ6F-OYMJC-NJBG7-YVOAO-NNFCV'

    return new Promise((resolve, reject) => {
        jsonp(`https://apis.map.qq.com/ws/location/v1/ip?output=jsonp&key=${mapKey}`, (err, data) => {
            if (err) {
                console.error('腾讯地图获取定位 失败', err.message);
            } else {
                // console.log(data.result);
                let from = data.result.location.lat + ',' + data.result.location.lng;
                jsonp(`https://apis.map.qq.com/ws/distance/v1/matrix/?output=jsonp&mode=driving&from=${from}&to=${destination}&key=${mapKey}`, (err, data) => {
                    if (err) {
                        console.error('腾讯地距离计算 失败', err.message);
                    } else {
                        let returnData = data.result.rows[0].elements[0]
                        resolve(returnData)
                    }
                });
            }
        });
    });

}

// 添加访问记录
const pv_log_add_log = (data) => {
    return request({
        url: '/client/pv_log/add_log',
        data,
        method: 'POST'
    })
}

// 更新访问记录时间
const pv_log_flush_time = (data) => {
    return request({
        url: '/client/pv_log/flush_time',
        data,
        method: 'POST'
    })
}

// 刷新访问时间
export const flush_time = (info) => {
    // console.log(info);
    // 更新访问记录时间
    let add_log_Code = window.localStorage.getItem("add_log_Code");
    if (add_log_Code) {
        pv_log_flush_time({
            code: add_log_Code,
        }).then(res => {
            // console.log('pv_log_flush_time', res);
        });
    }

    // 添加访问记录
    // setTimeout(() => {
    pv_log_add_log({
            url: info.path || '',
            paramters: JSON.stringify(info.query) || '',
            temp_user: window.localStorage.getItem("tempUserStr") || '',
            referrer: window.localStorage.getItem("referrer") || '',
            source: 'golfH5',
            channel: '',
            third_type: 'wechat',
            // identify: info.identify || '',
            page_name: info.meta.title || info.path == '/' ? '首页' : ''
        })
        .then((res) => {
            // console.log('pv_log_add_log');
            window.localStorage.setItem("add_log_Code", res.data.code);
            window.localStorage.setItem("tempUserStr", res.data.temp_user);
        });
    // }, 1000);
}

// wechat分享
export const wxShare = (shareInfo) => {
    // console.log(shareInfo);
    post('client/index/wxjsconfig', {
        url: shareInfo.link,
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
    }).then((res) => {
        // console.log(res);
        let data = res.data
        wx.config({
            debug: false, //是否打开debug调试模式。
            appId: data.appId, //必填，公众号唯一标识
            timestamp: data.timestamp, //必填，生成签名的时间戳
            nonceStr: data.nonceStr, //必填，生成签名的随机串
            signature: data.signature, //必填，生成的签名
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], //必填，允许分享好友，分享朋友圈
        })
        wx.error(function (err) {
            console.log(err)
        })
        wx.ready(() => {
            // 分享给朋友 及 分享到QQ
            wx.updateAppMessageShareData({
                title: shareInfo.title || '',
                desc: shareInfo.desc || '',
                imgUrl: shareInfo.imgUrl || '',
            })
            // 分享到朋友圈 及 分享到QQ空间
            wx.updateTimelineShareData({
                title: shareInfo.title || '',
                imgUrl: shareInfo.imgUrl || '',
            })
        })
    })
}