import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/index.scss'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'normalize.css'
import './utils/rem'

const app = createApp(App)

import { getResUrl, numKWFun, numKMFun } from './utils/tools'
app.config.globalProperties.$getResUrl = getResUrl
app.config.globalProperties.$numKWFun = numKWFun
app.config.globalProperties.$numKMFun = numKMFun

import { setCache, getCache, delCache } from './utils/cache'
app.config.globalProperties.$setCache = setCache
app.config.globalProperties.$getCache = getCache
app.config.globalProperties.$delCache = delCache

app.use(Vant).use(router).use(store).mount('#app')
