import config from '../../configApi'

// 跳小程序
// const goMiniApplets = () => {
//     window.open('weixin://dl/business/?t=XezVzIpwqIb')
// }

/**
 * 得到资源完整路径
 * @param {string} res 资源
 * @param {int} w 图片的最大宽度
 * @param {int} h 图片的最大高度
 */
export const getResUrl = (res, w = 0, h = 0) => {
	if (!res || typeof res !== 'string') {
		return ''
	}
	let fullUrl = ''
	if (res.indexOf('http') === 0) {
		if (res.indexOf('https') !== 0) {
			res = 'https' + res.substr(4)
		}
		fullUrl = res
	} else {
		fullUrl = config.resHost + res
	}
	if ((w > 0 || h > 0) && fullUrl.indexOf(config.resHost) === 0) {
		fullUrl = `${fullUrl}?x-oss-process=image/resize`
		if (w > 0) {
			fullUrl = fullUrl + `,w_${w}`
		}
		if (h > 0) {
			fullUrl = fullUrl + `,h_${h}`
		}
	}
	return fullUrl;
}

/**
 * 获取地址栏参数
 */
export const GetQueryString = (name) => {
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
	var r = window.location.search.substr(1).match(reg);
	if (r != null) return unescape(r[2]);
	return null;
}

const TOKEN_KEY = 'token_' + config.appId

const setToken = () => {
	localStorage.removeItem(TOKEN_KEY)
	localStorage.removeItem(TOKEN_KEY + 'exp_time')
}

/**
 * 从cookies中得到Token
 */
export const getToken = () => {
	const token = localStorage.getItem(TOKEN_KEY)
	const time = localStorage.getItem(TOKEN_KEY + 'exp_time')
	if (token && token != 'undefined' && time && time != 'undefined') {
		if ((new Date().getTime()) > time) {
			setToken()
			return false
		}
		return JSON.parse(token)
	} else {
		return false
	}
}

export const login = () => {
	return new Promise((resolve, reject) => {
		if (getToken()) {
			getUserInfo().then(res => {
				resolve()
				// console.log("getuserinfo", res);
			}).catch(err => {
				// console.log("getuserinfo err ", err);
			});
		} else {
			if (browser.wechat) {
				let code = GetQueryString("code");
				if (!code) {
					let opt = {
						url: window.location.href,
						scope: 'snsapi_base',
						proxyUrl: "https://c.xuelx.com/wxoauth.html" //TODO 测试用登陆代理
					};
					post("client/login/wechatoauth", opt).then(res => {
						window.location.href = res.url;
					});
				} else {
					loginWechat(code).then(res => {
						// console.log(res);
						resolve()
					}).catch(err => {
						// console.log(err);
						window.location.href = `${window.location.origin+'/'+window.location.hash}`
						// console.log("loginWechat err ", err);
					});
				}
			} else {
				resolve()
			}
		}
	})
}

// 数量 千/万
export const numKWFun = (num = 0) => {
	let number = Number(num)
	let numStr = ''
	if (number > 9999) {
		numStr = (Math.floor(number / 1000) / 10) + 'w'
	} else if (number > 999) {
		numStr = (Math.floor(number / 100) / 10) + 'k'
	} else {
		numStr = number
	}
	return numStr
}

// 距离 公里/米
export const numKMFun = (num) => {
	let str = ''
	num = Math.round(num * 100) / 100
	num = Number(num)
	if (num > 999) {
		str = Math.floor(num / 100) / 10
		str = str + '公里'
	} else {
		str = num + '米'
	}
	return str
}