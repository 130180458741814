// 简单的本地缓存操作

/**
 * 缓存变量到本地存储中
 * @param {String} key 键
 * @param {Object} value 值
 * @param {Number} exp 有效时间，单位秒，为0时表示不限
 */
export function setCache(key, value, exp = 0) {
    window.localStorage.setItem(key, value);
}

/**
 * 获取缓存值
 * @param {String} key
 * @param {*} defaultValue
 */
export function getCache(key) {
    let defaultValue = window.localStorage.getItem(key); //读取数据
    return defaultValue;
}

/**
 * 删除缓存
 * @param {String} key
 */
export function delCache(key) {
    localStorage.removeItem('key');
}

export default {
    setCache,
    getCache,
    delCache
}