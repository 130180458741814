const configApi = {
  /**
   * 由后端分配的appid,非小程序appid
   */
  appid: '4ab76ed3d6b59ce849ad3e12f889ace4',
  // xcxappid: 'wx80c9384372f2e886',
  /**
   * 接口服务器地址
   */
  // apiHost: 'https://test.wengwenggu.com/', //测试
  // domin: 'https://ctest.wengwenggu.com/#/', //测试
  // actDomin: 'https://acttest.wengwenggu.com/#/', //测试

  apiHost: 'https://api.wengwenggu.com/', //正式
  domin: 'https://c.wengwenggu.com/#/', //正式
  actDomin: 'https://act.wengwenggu.com/#/', //正式

  /**
   * 资源服务器地址
   */
  resHost: 'https://res.wengwenggu.com/',
}

export default configApi;