// import Cookies from 'js-cookie'
import config from '../../configApi'

export const TOKEN_KEY = 'token_' + config.appId
/**
 * 把Token存到cookies
 * @param {*} token
 */
const setToken = token => {
    if (token) {
        localStorage.setItem(TOKEN_KEY, JSON.stringify(token))
        localStorage.setItem(
            TOKEN_KEY + 'exp_time',
            new Date().getTime() + config.cookieExpires * 1 * 3600 * 1000
        )
    } else {
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(TOKEN_KEY + 'exp_time')
    }
}

/**
 * 从cookies中得到Token
 */
const getToken = () => {
    const token = localStorage.getItem(TOKEN_KEY)
    const time = localStorage.getItem(TOKEN_KEY + 'exp_time')
    if (token && token != 'undefined' && time && time != 'undefined') {
        if (new Date().getTime() > time) {
            setToken(false)
            return false
        }
        return JSON.parse(token)
    } else {
        return false
    }
}

export default {
    state: {
        token: getToken() || 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NTEyMDc5NjQsImRhdGEiOnsiYXBwaWQiOiI1Mjk0N2RmYjUzMWNiOGQxOGQwNTBhNzlhODAwYmMyYiIsImNvZGUiOiIwMDZEMzAxOTA5IiwidHlwZSI6IndlYXBwIn19.zK7beZ8hautYq8bSfnEKn3AgpTs82K2eatzeu595TxA', //会话
        userinfo: false, // 用户信息
        thirdinfo: false, // 绑定的第三方帐号信息
    },
    getters: {
        /**
         * 是否已登陆
         * @param {*} state
         */
        isLogined(state, getters, rootState) {
            return !!state.token
        },
    },
    mutations: {
        setToken(state, payload) {
            state.token = payload
            setToken(payload)
        },
        setUserinfo(state, payload) {
            state.userinfo = payload
        },
        setThirdinfo(state, payload) {
            state.thirdinfo = payload
        },
    },
    actions: {
        // 登录或刷新token后修改状态
        onLogin(context, data) {
            return new Promise((resolve, reject) => {
                context.commit('setToken', data.token)
                context.commit('setUserinfo', data.userinfo)
                context.commit('setThirdinfo', data.thirdinfo)
                resolve(data)
            })
        },
        // 退出登录后修改状态
        onLogout(context, data) {
            return new Promise((resolve, reject) => {
                context.commit('setToken', false)
                context.commit('setUserinfo', false)
                context.commit('setThirdinfo', false)
                resolve(data)
            })
        },
        // 得到用户信息时
        onUserInfo(context, data) {
            return new Promise((resolve, reject) => {
                context.commit('setUserinfo', data.userinfo)
                context.commit('setThirdinfo', data.thirdinfo)
                resolve(data)
            })
        },
    },
}