import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Golf',
        meta: {
            title: '高尔夫首页',
            keepAlive: true,
        },
        component: () => import('../views/Golf/index.vue'),
    },
    {
        path: '/golf/albumInfo',
        name: 'AlbumInfo',
        meta: {
            title: '相册详情',
        },
        component: () => import('../views/Golf/albumInfo.vue'),
    },
    {
        path: '/golf/downLoadImg',
        name: 'DownLoadImg',
        meta: {
            title: '图片下载',
        },
        component: () => import('../views/Golf/downLoadImg.vue'),
    },
    {
        path: '/golf/imgInfo',
        name: 'ImgInfo',
        meta: {
            title: '图片详情',
        },
        component: () => import('../views/Golf/imgInfo.vue'),
    },
    {
        path: '/Login',
        name: 'Login',
        meta: {
            title: '登录',
        },
        component: () => import('../views/Login/index.vue'),
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
